import { ReactNode } from 'react'

import { FireNewsCardProps } from '@nx/fire/news-card'
import { useUserInteraction } from '@nx/utils/google-tag-manager'

import {
  StyledFireCarousel,
  StyledFireNewsCard,
  StyledHeading,
} from './NewsAndVideos.styles'

export interface NewsAndVideosProps {
  title?: ReactNode
  items: FireNewsCardProps[]
  anchorId?: string
  newsItemHeadingTag: FireNewsCardProps['headingTag']
  trackingCategory: string
}

export function NewsAndVideos({
  title,
  items,
  anchorId,
  newsItemHeadingTag,
  trackingCategory,
}: NewsAndVideosProps) {
  const { recordInteraction } = useUserInteraction()

  if (!items?.length) {
    return null
  }

  return (
    <>
      {title && typeof title === 'string' ? (
        <StyledHeading as="h2" id={anchorId} $level={3}>
          {title}
        </StyledHeading>
      ) : (
        title
      )}
      <StyledFireCarousel fullWidthItems="mobileOnly">
        {items.map((item) => (
          <StyledFireNewsCard
            key={item.id}
            {...item}
            onClick={() => {
              recordInteraction({
                action: 'stories-card.click',
                category: trackingCategory,
                label: `${item.id}`,
              })
            }}
            headingTag={newsItemHeadingTag}
          />
        ))}
      </StyledFireCarousel>
    </>
  )
}
