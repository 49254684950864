import getConfig from 'next/config'

import { useGetUserAddresses } from '@nx/api'
import { AuctionLot } from '@nx/global-types'

import { useGetUser } from '@web/helpers/api/user'

import { mapAlgoliaLotToLotCardItem } from '../../../helpers/api'
import {
  StyledFireCarousel,
  StyledHeading,
  StyledLotCard,
} from './HomePageLots.styles'

const { publicRuntimeConfig } = getConfig()

export function HomePageLots({ title, lots }: HomePageLotsProps) {
  const { data: user } = useGetUser()

  const { data: userAddresses } = useGetUserAddresses({
    clientNo: user?.customerId || 0,
    baseUrl: publicRuntimeConfig.WEB_API_URL,
  })

  return (
    <>
      <StyledHeading $level={3} as="h2">
        {title}
      </StyledHeading>
      <StyledFireCarousel
        fullWidthItems="mobileOnly"
        isDesktopOnly
        alignment="baseline"
      >
        {lots.map((lot, index) => {
          return (
            <StyledLotCard
              key={`${lot.lotUniqueId}-${index}`}
              isShowingLotId={false}
              isFollowButtonDisplayed={false}
              lot={mapAlgoliaLotToLotCardItem(lot)}
              showBidNow
              showRegisterNow
              userHasAnAddress={!!userAddresses?.length}
              lotTracking={{ category: 'Home Page - Bid Now' }}
            />
          )
        })}
      </StyledFireCarousel>
    </>
  )
}

interface HomePageLotsProps {
  title: string
  lots: AuctionLot[]
}
