import { useQuery } from '@tanstack/react-query'

import { getUserAddresses } from './getUserAddresses'

export function useGetUserAddresses({
  clientNo = 0,
  baseUrl,
}: UseUserAddressesProps) {
  return useQuery({
    queryKey: ['userAddresses', { clientNo }],
    queryFn: async () => {
      const response = await getUserAddresses(clientNo, baseUrl)

      return response
    },
    retry: 1,
    enabled: Boolean(clientNo),
    refetchOnWindowFocus: true,
    staleTime: 300000, // 5 minutes.
  })
}

export interface UseUserAddressesProps {
  clientNo?: number
  baseUrl: string
}
