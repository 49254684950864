import { FireButton } from '@nx/fire/button'
import { Body, Heading } from '@nx/fire/typography'

import { ContentDescription } from './HeroImageCarouselInfoBox.styles'

export function HeroImageCarouselInfoBox({
  title,
  description,
  ctaTitle,
  link,
  dateAndLocation,
}: HeroImageCarouselInfoBoxProps) {
  return (
    <>
      <Heading
        as="h2"
        $level={5}
        dangerouslySetInnerHTML={{
          __html: title,
        }}
      />

      {description && (
        <ContentDescription $withMargin={false}>
          <Body dangerouslySetInnerHTML={{ __html: description }} />
        </ContentDescription>
      )}

      <ContentDescription $withMargin={true}>
        <Body>{dateAndLocation}</Body>
      </ContentDescription>

      <FireButton variant="label" href={link}>
        {ctaTitle}
      </FireButton>
    </>
  )
}

interface HeroImageCarouselInfoBoxProps {
  title: string
  description: string
  ctaTitle: string
  link: string
  dateAndLocation: string
}
