import styled, { css } from 'styled-components'

export const ContentDescription = styled.div<{ $withMargin?: boolean }>`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  ${({ $withMargin }) =>
    $withMargin &&
    css`
      margin-bottom: 5px;
    `}
  font-style: italic;
`
