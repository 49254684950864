import { AlgoliaAuction } from '@nx/algolia'
import { Heading } from '@nx/fire/typography'
import { TypesenseAuction } from '@nx/typesense'

import { mapAlgoliaAuctionToGridItem } from '@web/helpers/api'
import { AuctionClickCallback } from '@web/types/app'

import { AuctionGrid, AuctionGridItem } from '../../common/AuctionGrid'
import {
  StyledButtonContainer,
  StyledFireButton,
} from '../HomePage/HomePage.styles'

export function HomePageAuctions({
  title,
  auctions,
  ctaText,
  pageIdPrefix,
  auctionClickCallback,
}: HomePageAuctionsProps) {
  return (
    <>
      <Heading $level={3} as="h2">
        {title}
      </Heading>
      <AuctionGrid
        auctionTitleTag="h3"
        pageIdPrefix={pageIdPrefix}
        auctions={
          auctions.map(mapAlgoliaAuctionToGridItem) as AuctionGridItem[]
        }
        auctionClickCallback={(props) =>
          auctionClickCallback?.({
            ...props,
            category: 'Recommended Auctions',
          })
        }
      />
      {ctaText && (
        <StyledButtonContainer>
          <StyledFireButton variant="label" size="L" href="/auctions/upcoming/">
            {ctaText}
          </StyledFireButton>
        </StyledButtonContainer>
      )}
    </>
  )
}

interface HomePageAuctionsProps {
  title: string
  auctions: (AlgoliaAuction | TypesenseAuction)[]
  ctaText?: string
  pageIdPrefix: string
  auctionClickCallback?: AuctionClickCallback
}
